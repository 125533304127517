<template>
  <div id="container">
  <div class="header" v-if="ces">
        <van-nav-bar
        title=""
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        />
    </div>
    <div class="logo" v-if="ces">
        <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
    </div>
    <div class="content" v-if="ces">
    <!-- 轮播图 -->
     <!-- 轮播图 -->
     <div v-if="images!='' && images.length>0" style="height:138px;padding: 7px 5px;background: #fff;
    border-radius: 5px;display: flex;">
    <ul style="width: 100%;border-radius: 5px !important;display: flex;">
      <li class="li_img" style="margin-right: 3px;border-radius: 5px !important;width: 25%;" v-for="(image, index) in images" :key="index">
        <van-image
        @click="sceneImg(image,images.length)"
          style="border-radius: 5px !important;"
          width="100%"
          height="100%"
          :src="image"
        />
      </li>
    </ul>

     

    <!-- <div class="swiper" v-if="images!=''">
        <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image" />
        </van-swipe-item>
        </van-swipe>
    </div> -->
    </div>

    <!-- 帖子描述 -->
    <div class="info_body">
        <div class="title">
            {{info.title}}
        </div>
        <div style="color: #a3a3a3; line-height: 24px;text-align:left">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(info.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ info.viewCount }}</span>
        </div>
        <div class="miaoshu">
            <span style="font-weight: 700;color: #000;">地区:</span>&nbsp;&nbsp;{{info.address}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">年龄:</span>&nbsp;&nbsp;{{info.girlAge}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">颜值:</span>&nbsp;&nbsp;{{info.girlBeauty}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">价格:</span>&nbsp;&nbsp;{{info.consumeLv}}
        </div>
        <div class="miaoshu">
          <span style="font-weight: 700;color: #000;">服务:</span>&nbsp;&nbsp;{{info.serveList}}
        </div>
    </div>
    <!-- vip提示 -->
    <div class="vipTip">
      本站为信息分享平台，并不对寻欢经历负责，请注意个人防范。凡是有要求路费/.上门/红包/定金/保证金/照片验证/视频验证/提前付费
      等类似行为的都是骗子，
      同时也请注意任何形式的推荐办卡行为、引导下载APP行为，请勿上当受骗。碰到有问题的信息，请及时举报给我们删除信息。
      如果发布的信息涉及个人隐私，也请及时举报，我们会核实后第一时间帮你删除处理。
    </div>
    <!-- 帖子信息 -->
    <div class="info">
        <div class="miaoshu" style="color:#ec4899">
            联系方式
        </div>
        <div class="miaoshu" v-if=" info.qq && info.qq!=''">
          <span style="font-weight: 700;color: #000;">QQ:</span>&nbsp;&nbsp;<span id="qq" @click="copyText('qq')">{{info.qq}}</span>
        </div>
        <div class="miaoshu" v-if=" info.wechat && info.wechat!=''">
          <span style="font-weight: 700;color: #000;">微信:</span>&nbsp;&nbsp;<span id="vx" @click="copyText('vx')">{{info.wechat}}</span>
        </div>
        <div class="miaoshu" v-if=" info.phone && info.phone!=''">
          <span style="font-weight: 700;color: #000;">手机:</span>&nbsp;&nbsp;<span id="phone" @click="copyText('phone')">{{info.phone}}</span>
        </div>
        <div class="miaoshu" v-if=" info.yuni && info.yuni!=''">
          <span style="font-weight: 700;color: #000;">与你:</span>&nbsp;&nbsp;<span id="yuni" @click="copyText('yuni')">{{info.yuni}}</span>
        </div>
        <div class="miaoshu" v-if=" info.telegram && info.telegram!=''">
          <span style="font-weight: 700;color: #000;">飞机:</span>&nbsp;&nbsp;<span id="telegram" @click="copyText('telegram')">{{info.telegram}}</span>
        </div>
        <div class="miaoshu" v-if=" info.address && info.address!=''">
          <span style="font-weight: 700;color: #000;">地址:</span>&nbsp;&nbsp;<span id="adress" @click="copyText('adress')">{{info.address}}</span>
        </div>
    </div>
   
    <!-- 帖子描述 -->
    <div class="desc">
      <span style="font-weight: 700;color: #000;">体验描述:</span>&nbsp;&nbsp;{{info.desc}}
    </div>
    </div>
      <Loder ref="dialog"></Loder>
  </div>
</template>

<script>
// import { Dialog } from 'vant';
import { getDataById } from "../../model/index"
import { getJFXX,payTZ,insetIds,getTZ } from "../../model/user"
import moment from "moment";
import { Toast } from 'vant';
import { mapState,mapMutations } from "vuex"
import Loder  from './compoments/index.vue'
import { v4 as uuidv4 } from 'uuid';
import { ImagePreview } from 'vant';

export default {
    components: {
      Loder
    },
    data() {
        return {
            id:"",
            images: [],
            // baseUrl: "https://r1.img7.xyz/info/picture/",
          // baseUrl: "http://38.54.111.82/info/picture/",
          baseUrl: "http://38.54.111.182/info/picture/",
            status:2,   // 0 未登录  1普通用户 2会员账号
            info:"",
            sjhyShow:false,
            jfpayShow:false,
            titleTZ:"",
            ces:false,
            buyIds:[],
            ids:"",
        }
    },
   watch:{
      'info'(val){
        if(val){
        console.log(val,1566666);
        setTimeout(()=>{
          this.ces = true
        },500)
        this.$nextTick(()=>{
          this.$refs['dialog'].changeVisable()
        })
        }

        
      }
    },
    created() {
        this.id = this.$route.query.id
        this.getData()
        
    },
    computed:{
        ...mapMutations(["changeFlag","changeFlag1"]),
        ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
    },
 


  methods: {
          // 图片预览
    sceneImg(images, index) {
      if (!this.loginStatus) { 
        Toast.fail('请登陆后查看！')
      } else {
        // if (this.userInfo.usertype > 2) {
          ImagePreview({
            images: [images], //需要预览的图片 URL 数组
            showIndex:false, //是否显示页码
            loop:false, //是否开启循环播放
            startPosition:index //图片预览起始位置索引
          })
        // } else { 
        //   Dialog.alert({
        //     title: '温馨提示',
        //     message: '仅积分购买此联系方式的用户和VIP会员可使用图片放大功能,请使用积分购买或升级VIP会员。',
        //   }).then(() => {
        //     // on close
        //   });
        // }
      
      }
       
        },
        sj(){
          this.sjhyShow = true
          let time = this.$getNYRSFM()
          let uuid = uuidv4()
          if(this.$store.state.user.loginStatus){
            this.$operLog('点击会员升级',uuid,this.$store.state.user.userInfo.username,this.$store.state.user.userInfo.usertype,this.$store.state.user.userInfo.createTime,window.location.host,time)
          }else{
            this.$operLog('点击会员升级',uuid,'','','',window.location.host,time)
          }

        },
           // 会员升级
        hysj(){
          alert("请到个人中心进行升级！")
        },
        // 季度会员
        jdhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://ss.xhfaka.cc//details/54402BFE"
        },
        // 年度会员
        ndhy(){
          // window.location.href="http://dolve.xyz"
           window.location.href="https://ss.xhfaka.cc//details/47F94086"

        },
        // 永久会员
        yjhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://ss.xhfaka.cc//details/FE2B8DC3"

        },
        orderQuery(){
          window.location.href="https://ss.xhfaka.cc/orderquery"
        },
        // 升级会员弹窗
        sjhy(){
          this.sjhyShow = true
        },
        getData(){
            let data= {
                id:this.id
            }
            getDataById(data).then((res)=>{
                console.log(res.data.data,55);
                this.info = res.data.data[0]
                let fbTimer = res.data.data[0].publishedAt
                console.log(this.info,66);
                let arrPic = res.data.data[0].picture.split(",")
                if(arrPic&&arrPic.length!=""){
                 let arrList = arrPic.map((item)=>{
                    if(item.indexOf('/') != -1){
                      return this.baseUrl  + item
                    }
                    else{
                      return this.baseUrl +this.filterTimePIC(fbTimer)+'/' + item
                    }
                 })
                    // let picList = 
                    // this.images = this.images.push(pic)
                    console.log(arrList,55555,arrPic);
                    this.images = arrList
                }
            })
        },
        // 积分解锁
        jfPay(){
          this.jfpayShow = true
        },
        pay(){
          // console.log(this.userInfo,123);

          let data = {
            username:this.userInfo.username
          }
          getTZ(data).then((res)=>{
            if(res.status == 200){
              console.log(res.data.data[0].buyed,12333333);
             
              this.ids = res.data.data[0].buyed
              // this.buyIds.push(res.data.data[0].buyed)
               this.buyIds = res.data.data[0].buyed.split(',')
               console.log(this.buyIds,133);
          
                
                if(this.buyIds.indexOf(this.id) != -1){
                 Toast.success('请勿重复解锁,请回主页查看');
                }else{
                  getJFXX(data).then((res)=>{
                  if(res.status == 200){
                    // console.log(res,156);
                    let jfNum = res.data.data[0].score
                    console.log(jfNum);
                    if(jfNum - 20 < 0){
                      Toast.fail('积分不足.请先充值！')
                    }else{
                      let data = {
                        username:this.userInfo.username,
                        score:jfNum - 20
                      }
                      payTZ(data).then((res)=>{
                        if(res.status == 200){
                          // console.log(res,123);
                          this.jfpayShow = false
                          let data = {
                            burId:this.id+','+this.ids,
                            username:this.userInfo.username
                          }
                          insetIds(data).then((res)=>{
                            if(res.status == 200){
                              Toast.success('已解锁,请回主页查看');
                            }
                          })
                        }
                      })
                    }
                  }
          })
                }
             
            }
          })
          

        },
        onClickLeft(){
          
            // this.$router.back()
            window.history.back(-1)
         
        },
        // onClickRight(){
        // },
 
        // 格式化时间
        filterTime(value) {
        if (value != null || value != "") {
            let val = value;
            let momentObj = moment(val * 1).format("YYYY-MM-DD");
            return momentObj;
        } else {
            return "";
        }
        },
        // 去登陆
        goLogin(){
            this.$router.push({
                path:'./login'
            })
        },
      filterTimePIC(value) {
        if (value != null || value != "") {
          let val = value;
          let momentObj = moment(val * 1).format("YYMMDD");
          console.log(momentObj,"156666");
          return momentObj;
        } else {
          return "";
        }
      },
      copyText(id) { //传id名
        const copyDOM = document.getElementById(id) // 获取将要复制的dom
        if (!copyDOM) {
          Toast.success('没有内容')
          return;
        }
        // 创建text area
        const textArea = document.createElement('textarea')
        textArea.value = copyDOM.innerText
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute'
        textArea.style.opacity = '0'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select() // 选中文本
        const successful = document.execCommand('copy') // 执行 copy 操作
        if (successful) {
          Toast.success('复制成功！')
        } else {
        Toast.success('复制失败，请手动复制！')
        }
        textArea.remove()
      }
    },
async mounted() {
    // this.ces = true
      // this.ces = true
    this.changeFlag();
  },
  destroyed() {
    this.changeFlag1();
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
   
}
#container .logo{
    position: absolute;
    top: 10px;
    left: 46%;
    z-index: 99;
}
#container .logo img{
    height: 45px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
}

 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
.content .info_body{
    box-sizing: border-box;
    width: 100%;
    height: 225px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .info_body .title{
    height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
}
.content .info_body .miaoshu{
    text-align: left;
    font-size: 14px;
    color: #525252;
    line-height: 30px;
    /* overflow: hidden; */
    height: 30px;
    white-space:nowrap;
   overflow:hidden;
   text-overflow:ellipsis;
}
.content .login_tip{
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .login_tip .tips{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
}
.content .login_tip .login_btn{
    height: 50px;
}
.content .login_tip .aaa{
    height: 55px;
    margin-top: 4px;
    cursor: pointer;
}
.content .login_tip .aaa span{
    display: block;
    font-size: 14px;
    float: left;
    color: #ec4899;
    margin-top: -2px;
}
.content .login_tip .aaa .bbb{
    float: left;
    width: 300px;
}
.content .login_tip .aaa .bbb li{
    height: 25px;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    color: #ec4899;
}
.content .desc{
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
}
.content .info{
    box-sizing: border-box;
    width: 100%;
    /* height: 170px; */
    height: auto;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .info .miaoshu{
    text-align: left;
    font-size: 14px;
    color: #525252;
    line-height: 30px;
    overflow: hidden;
}
.content .vipTip{
  box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    color: goldenrod;
}
.content .notVip{
    box-sizing: border-box;
    width: 100%;
    height: 140px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .notVip .text{
    width: 100%;
    font-size: 14px;
    text-align: left;
}
 .content .notVip .login_btn{
    height: 50px;
    text-align: left;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}


.content_tip{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 5px 10px  0;
  box-sizing: border-box;
}
.content_tip .content{
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  padding: 10px 5px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.content_tip .content .font{
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}
.vipBtn{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  /* line-height: 60px; */
  margin-top: 15px;
}
.vipBtn li{
  background-color: #ec4899;
  width: 25%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.shengji{
  width: 100%;
  height: 50px;
}
.shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
.shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
table,table tr th,table tr td{
  border: 1px solid #ccc;
}
table{
  border-collapse: collapse;
  width: 100%;
}

.jfPay{
  box-sizing: border-box;
  padding: 0 20px;
  height: 40%;
  font-size: 18px;
  color: #ec4899;
}
.jfPay .content{
  text-align: left;
  /* height: calc(100% - 50px); */
  height: 100%;
}
.jfPay .content .subPay{
  text-align: center;
  margin-top: 40px;
}
.jfPay .content .price{
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
}
.content /deep/ .van-action-sheet__close{
  right: 15px;
  color: #ec4899;
} 
.li_img /deep/.van-image img{
  border-radius: 5px !important;
}
</style>